var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c("my-navigation"),
      _vm._v(" "),
      _c("main", [
        _vm._m(0),
        _vm._v(" "),
        _c("section", { staticClass: "section", attrs: { id: "about" } }, [
          _c("div", { staticClass: "container" }, [
            _c("h1", { staticClass: "title" }, [_vm._v("About")]),
            _vm._v(" "),
            _c("div", { staticClass: "columns is-vcentered" }, [
              _c("div", { staticClass: "column is-4" }, [
                _c("figure", { staticClass: "image" }, [
                  _c("img", {
                    staticClass: "is-rounded",
                    attrs: {
                      width: "1580",
                      height: "1077",
                      loading: "lazy",
                      alt: "Profile Image",
                      src: _vm.profilePicture
                    }
                  })
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "column is-1" }),
              _vm._v(" "),
              _vm._m(1)
            ])
          ])
        ]),
        _vm._v(" "),
        _vm._m(2),
        _vm._v(" "),
        _c("section", { staticClass: "section", attrs: { id: "technology" } }, [
          _c("div", { staticClass: "container" }, [
            _c("h1", { staticClass: "title" }, [_vm._v("Technology")]),
            _vm._v(" "),
            _c("h1", { staticClass: "subtitle" }, [
              _vm._v("Some tech I work with...")
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "columns is-multiline" },
              _vm._l(_vm.technologies, function(technology, index) {
                return _c("div", { key: index, staticClass: "column is-4" }, [
                  _c("div", { staticClass: "card" }, [
                    _c("div", { staticClass: "card-image" }, [
                      _c(
                        "figure",
                        { staticClass: "image icon-image is-64x64" },
                        [
                          _c("img", {
                            staticStyle: { "border-radius": "30px" },
                            attrs: {
                              src: technology.img,
                              alt: "Placeholder image"
                            }
                          })
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "card-content" }, [
                      _c("div", { staticClass: "title" }, [
                        _vm._v(
                          "\n                  " +
                            _vm._s(technology.title) +
                            "\n                "
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(
                          "\n                  " +
                            _vm._s(technology.body) +
                            "\n                "
                        )
                      ])
                    ])
                  ])
                ])
              }),
              0
            )
          ])
        ]),
        _vm._v(" "),
        _vm._m(3),
        _vm._v(" "),
        _c("section", { staticClass: "section", attrs: { id: "articles" } }, [
          _c("div", { staticClass: "container" }, [
            _c("h1", { staticClass: "title" }, [_vm._v("Articles")]),
            _vm._v(" "),
            _c("h1", { staticClass: "subtitle" }, [
              _vm._v("Articles I like...")
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "columns is-multiline" },
              _vm._l(_vm.articles, function(article, index) {
                return _c("div", { key: index, staticClass: "column is-4" }, [
                  _c("div", { staticClass: "card" }, [
                    _c("div", { staticClass: "card-content" }, [
                      _c("p", { staticClass: "title" }, [
                        _vm._v(
                          "\n                  " +
                            _vm._s(article.title) +
                            "\n                "
                        )
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "subtitle date-text" }, [
                        _vm._v(
                          "\n                  " +
                            _vm._s(article.date) +
                            "\n                "
                        )
                      ]),
                      _vm._v(" "),
                      _c("hr"),
                      _vm._v(" "),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(
                          "\n                  " +
                            _vm._s(article.description) +
                            "\n                "
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("footer", { staticClass: "card-footer" }, [
                      _c(
                        "a",
                        {
                          staticClass: "card-footer-item",
                          attrs: { href: article.url }
                        },
                        [_vm._v("Find Out More")]
                      )
                    ])
                  ])
                ])
              }),
              0
            )
          ])
        ]),
        _vm._v(" "),
        _vm._m(4),
        _vm._v(" "),
        _c("section", { staticClass: "section", attrs: { id: "contact" } }, [
          _c("div", { staticClass: "container" }, [
            _c("h1", { staticClass: "title" }, [_vm._v("Contact")]),
            _vm._v(" "),
            _c("div", { staticClass: "columns  is-centered" }, [
              _c("div", { staticClass: "column is-half" }, [
                _c("div", { staticClass: "field" }, [
                  _c("div", { staticClass: "control" }, [
                    _c("label", { attrs: { for: "name" } }),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.name,
                          expression: "name"
                        }
                      ],
                      staticClass: "input is-info",
                      attrs: {
                        type: "text",
                        id: "name",
                        name: "name",
                        placeholder: "Name"
                      },
                      domProps: { value: _vm.name },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.name = $event.target.value
                        }
                      }
                    })
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "field" }, [
                  _c("div", { staticClass: "control" }, [
                    _c("label", { attrs: { for: "email" } }),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.email,
                          expression: "email"
                        }
                      ],
                      staticClass: "input is-info",
                      attrs: {
                        type: "email",
                        id: "email",
                        name: "email",
                        placeholder: "Email"
                      },
                      domProps: { value: _vm.email },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.email = $event.target.value
                        }
                      }
                    })
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "field" }, [
                  _c("div", { staticClass: "control" }, [
                    _c("label", { attrs: { for: "message" } }),
                    _vm._v(" "),
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.message,
                          expression: "message"
                        }
                      ],
                      staticClass: "textarea is-info",
                      attrs: {
                        id: "message",
                        name: "message",
                        placeholder: "Message"
                      },
                      domProps: { value: _vm.message },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.message = $event.target.value
                        }
                      }
                    })
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "button is-info",
                    on: { click: _vm.sendMessage }
                  },
                  [_vm._v("\n              Send Message\n            ")]
                )
              ])
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("my-footer")
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "hero is-info" }, [
      _c("div", { staticClass: "hero-body" }, [
        _c("div", { staticClass: "container" }, [
          _c("h1", { staticClass: "title" }, [
            _vm._v("\n            Stephen Cassedy\n          ")
          ]),
          _vm._v(" "),
          _c("h1", { staticClass: "subtitle" }, [
            _vm._v("\n            Welcome to my site!\n          ")
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "column is-6" }, [
      _c("p", { staticClass: "has-text-justified" }, [
        _vm._v("\n              Hi! My name is "),
        _c("strong", [_vm._v("Stephen Cassedy")]),
        _vm._v(
          ". I'm very\n              passionate and dedicated to my work. With two years experience\n              in fullstack development, I have acquired many skills required\n              to build richly featured products. I currently work at\n              "
        ),
        _c("strong", [
          _c("a", { attrs: { href: "https://cellusys.com" } }, [
            _vm._v("Cellusys")
          ])
        ]),
        _vm._v(" in\n              the "),
        _c("strong", [_vm._v("Internet of Things")]),
        _vm._v(
          "\n              department. I enjoy every step of the development process from\n              requirements gathering to deployment. If you wish to contact me,\n              please don't hesitate to get in touch!\n            "
        )
      ]),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c("div", { staticClass: "columns" }, [
        _c(
          "span",
          { staticClass: "column", staticStyle: { "text-align": "left" } },
          [_c("strong", [_vm._v("Birthday:")]), _vm._v(" 01.07.1995")]
        ),
        _vm._v(" "),
        _c(
          "span",
          { staticClass: "column", staticStyle: { "text-align": "left" } },
          [_c("strong", [_vm._v("Profession:")]), _vm._v(" Software Engineer")]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "columns" }, [
        _c(
          "span",
          { staticClass: "column", staticStyle: { "text-align": "left" } },
          [_c("strong", [_vm._v("Home:")]), _vm._v(" Dublin, Ireland")]
        ),
        _vm._v(" "),
        _c(
          "span",
          { staticClass: "column", staticStyle: { "text-align": "left" } },
          [
            _c("strong", [_vm._v("Degree:")]),
            _vm._v(" BSc. in Computer Applications")
          ]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "columns" }, [
        _c(
          "span",
          { staticClass: "column", staticStyle: { "text-align": "left" } },
          [
            _c("strong", [_vm._v("Mail:")]),
            _vm._v(" "),
            _c("a", { attrs: { href: "mailto:casseds95@gmail.com" } }, [
              _vm._v("casseds95@gmail.com")
            ])
          ]
        ),
        _vm._v(" "),
        _c(
          "span",
          { staticClass: "column", staticStyle: { "text-align": "left" } },
          [_c("strong", [_vm._v("Mobile:")]), _vm._v(" 0851185656")]
        )
      ]),
      _vm._v(" "),
      _c("button", { staticClass: "button is-info" }, [
        _c(
          "a",
          { staticStyle: { color: "white" }, attrs: { href: "#contact" } },
          [_vm._v("Contact")]
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "container" }, [
      _c("hr", { staticClass: "separator" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "container" }, [
      _c("hr", { staticClass: "separator" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "container" }, [
      _c("hr", { staticClass: "separator" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }