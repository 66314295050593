var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "section" }, [
      _c("footer", { staticClass: "footer" }, [
        _c("div", { staticClass: "content has-text-centered" }, [
          _c("a", { attrs: { href: "https://github.com/casseds2" } }, [
            _c("span", { staticClass: "icon is-large has-text-black" }, [
              _c("i", { staticClass: "fab fa-github fa-lg" })
            ])
          ]),
          _vm._v(" "),
          _c(
            "a",
            {
              attrs: {
                href: "https://www.linkedin.com/in/stephen-cassedy-178454ba/"
              }
            },
            [
              _c("span", { staticClass: "icon is-large has-text-info" }, [
                _c("i", { staticClass: "fab fa-linkedin fa-lg" })
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "a",
            { attrs: { href: "https://www.facebook.com/stephen.cassedy" } },
            [
              _c("span", { staticClass: "icon is-large has-text-link" }, [
                _c("i", { staticClass: "fab fa-facebook fa-lg" })
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              attrs: { href: "https://www.instagram.com/staevecassedy/?hl=en" }
            },
            [
              _c("span", { staticClass: "icon is-large has-text-danger" }, [
                _c("i", { staticClass: "fab fa-instagram fa-lg" })
              ])
            ]
          ),
          _vm._v(" "),
          _c("a", { attrs: { href: "https://twitter.com/Phtephens" } }, [
            _c("span", { staticClass: "icon is-large has-text-info" }, [
              _c("i", { staticClass: "fab fa-twitter fa-lg" })
            ])
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }