var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "nav",
    {
      staticClass: "navbar is-fixed-top",
      attrs: { role: "navigation", id: "navigation" }
    },
    [
      _c(
        "div",
        { staticClass: "navbar-menu", class: { "is-active": _vm.isShown } },
        [
          _c("div", { staticClass: "navbar-start" }, [
            _c(
              "a",
              {
                staticClass: "navbar-item",
                attrs: { href: "#about" },
                on: {
                  click: function($event) {
                    _vm.isShown = !_vm.isShown
                  }
                }
              },
              [_c("strong", [_vm._v("About")])]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "navbar-item",
                attrs: { href: "#technology" },
                on: {
                  click: function($event) {
                    _vm.isShown = !_vm.isShown
                  }
                }
              },
              [_c("strong", [_vm._v("Technology")])]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "navbar-item",
                attrs: { href: "#articles" },
                on: {
                  click: function($event) {
                    _vm.isShown = !_vm.isShown
                  }
                }
              },
              [_c("strong", [_vm._v("Articles")])]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "navbar-item",
                attrs: { href: "#contact" },
                on: {
                  click: function($event) {
                    _vm.isShown = !_vm.isShown
                  }
                }
              },
              [_c("strong", [_vm._v("Contact")])]
            )
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "navbar-burger",
          class: { "is-active": _vm.isShown },
          on: {
            click: function($event) {
              _vm.isShown = !_vm.isShown
            }
          }
        },
        [
          _c("span", { attrs: { "aria-hidden": "true" } }),
          _vm._v(" "),
          _c("span", { attrs: { "aria-hidden": "true" } }),
          _vm._v(" "),
          _c("span", { attrs: { "aria-hidden": "true" } })
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }