<template>
  <section class="section">
    <footer class="footer">
      <div class="content has-text-centered">
        <a href="https://github.com/casseds2">
          <span class="icon is-large has-text-black">
            <i class="fab fa-github fa-lg"></i>
          </span>
        </a>
        <a href="https://www.linkedin.com/in/stephen-cassedy-178454ba/">
          <span class="icon is-large has-text-info">
            <i class="fab fa-linkedin fa-lg"></i>
          </span>
        </a>
        <a href="https://www.facebook.com/stephen.cassedy">
          <span class="icon is-large has-text-link">
            <i class="fab fa-facebook fa-lg"></i>
          </span>
        </a>
        <a href="https://www.instagram.com/staevecassedy/?hl=en">
          <span class="icon is-large has-text-danger">
            <i class="fab fa-instagram fa-lg"></i>
          </span>
        </a>
        <a href="https://twitter.com/Phtephens">
          <span class="icon is-large has-text-info">
            <i class="fab fa-twitter fa-lg"></i>
          </span>
        </a>
      </div>
    </footer>
  </section>
</template>

<script>
export default {
  name: "myfooter",
};
</script>

<style></style>
